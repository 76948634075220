import { render, staticRenderFns } from "./FFSupplierTransactionRows.vue?vue&type=template&id=6d3f6c45&scoped=true"
import script from "./FFSupplierTransactionRows.vue?vue&type=script&lang=ts"
export * from "./FFSupplierTransactionRows.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d3f6c45",
  null
  
)

export default component.exports