
import Vue from 'vue'
import { IHeaderOption, KwikMediumContainer, KwikPageToolbar } from 'kwik-vue'
import { ISupplierTransactionRow } from '../types'
import { dispatchGetSupplierTransactionRows } from '../store/actions'

export default Vue.extend({
  name: 'FFSupplierTransactionRows',
  components: { KwikMediumContainer, KwikPageToolbar },
  data () {
    return {
      options: {} as any,
      loading: true,
      supplierTransactionRowItems: [] as ISupplierTransactionRow[],
      total: 0,
      breadcrumbs: [
        { text: 'Food Factory', disabled: true },
        { text: 'Transazioni fornitori', disabled: true },
        { text: 'Dettaglio transazione', disabled: false }
      ]
    }
  },
  computed: {
    headers () : IHeaderOption[] {
      const defaultHeaders = [
        // {
        //   text: 'ID',
        //   sortable: true,
        //   value: 'id',
        //   hidden: true,
        //   align: 'start'
        // },
        {
          text: 'Materiale',
          sortable: true,
          value: 'material.name',
          hidden: false,
          align: 'start'
        },
        {
          text: 'UM',
          sortable: true,
          value: 'material.unit_of_measure',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Quantità richiesta',
          sortable: true,
          value: 'requested_quantity',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Prezzo unitario',
          sortable: true,
          value: 'unit_price',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Totale riga',
          sortable: true,
          value: 'total',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lunga scadenza',
          sortable: true,
          value: 'requested_long_term',
          hidden: false,
          align: 'start'
        },
        {
          text: 'Lotto',
          sortable: true,
          value: 'batch',
          hidden: false,
          align: 'start'
        }
      ] as IHeaderOption[]
      return defaultHeaders
    }
  },
  methods: {
    async loadPage (supplierTransactionId: number) {
      this.loading = true
      const response = await dispatchGetSupplierTransactionRows(this.$store, supplierTransactionId)
      this.supplierTransactionRowItems = response.data
      this.loading = false
    },
    getBatchLabel (item: ISupplierTransactionRow) {
      if (item.batch === null) return '---'
      else if (item.batch.state === 'GHOST') return '---'
      else if (item.batch.state === 'CANCELLED') return '---'
      else return item.batch.code
    }
  },
  async mounted () {
    const supplierTransactionId = +this.$router.currentRoute.params.supplier_transaction_id
    this.loadPage(supplierTransactionId)
  },
  watch: {
    supplierTransactionRowItems: {
      handler (val: ISupplierTransactionRow[]) {
        this.total = val.reduce((sum, item) => sum + (item.unit_price * item.requested_quantity), 0)
      }
    }
  }
})
